<div data-toggle="modal" class="parent-text" data-target="#exampleModalLong">
  <a class="cstm-anchor-primary">
    Click here to see the video
    <strong> <img alt="" src="../../../../../assets/images_and_icons/right-arrow.svg"></strong>
  </a>
</div>
<div class="modal fade" tabindex="-1" id="exampleModalLong" role="dialog" aria-labelledby="exampleModalLongTitle"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" data-target="#closeVideo" (click)="stopVideo(elRef)"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="closeVideo">
        <video #elRef style="width: 100%; height: 100%;" controls controlsList="nodownload">
          <source
            src="https://backoffice.c4khy-siemensga1-p1-public.model-t.cc.commerce.ondemand.com/medias/FAQ-Access-Request-Q1-Video.mp4?context=bWFzdGVyfHJvb3R8MTM3NjY2MXx2aWRlby9tcDR8YUdFekwyZ3dZUzg1TlRVNU56WTFNakl4TkRBMkwwWkJVU0JCWTJObGMzTWdVbVZ4ZFdWemRDQlJNU0JXYVdSbGJ5NXRjRFF8YzI3ZTQxNTEzZDc0NTY5NWFkODk4ZTA3ZGU3NDA0ODg3YjJmNDQzZDk3ZWQ3MTA4MWM1OTM3YjNlNzA2N2Q5Mg"
            type="video/mp4">
        </video>
      </div>
    </div>
  </div>
</div>