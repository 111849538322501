import { Component, ElementRef, ViewChild } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { environment } from '../../../../../environments/environment';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';

@Component({
  selector: 'app-faq-video-popup',
  templateUrl: './faq-video-popup.component.html',
  styleUrl: './faq-video-popup.component.scss',
})
export class FaqVideoPopupComponent {
  @ViewChild('videoPlayer') el: ElementRef;
  videoPlayer: ElementRef<HTMLVideoElement>;
  public componentData: any;
  constructor(
    public component: CmsComponentData<any>,
    private elRef: ElementRef,
    private globalService: GlobalService
  ) {}

  ngOnInit(): void {
    this.component.data$.subscribe((data) => {
      this.componentData = data;
    });
  }

  ngAfterViewInit(): void {
    const modalElement = this.elRef.nativeElement.querySelector('#closeVideo');
    modalElement.addEventListener('hidden.bs.modal', () => {});
  }

  stopVideo(video) {
    video.pause();
  }
}
